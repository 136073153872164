





























import {PartnerInterface} from '@/shared/interfaces/store/homepage/partner.interface';
import {HomepageStore} from '@/store/modules/homepage';
import {Component, Vue} from 'vue-property-decorator';

@Component
export default class HomePartners extends Vue {

  showText = -1;

  get partners(): PartnerInterface[] {
    return HomepageStore.partners;
  };

  get title(): string {
    return HomepageStore.partnersMainTitle;
  };

}
